import React, { useRef, useEffect } from "react";
import "./survey-results.scss";
import { Link } from "../links";
import gsap from "gsap";
import classNames from "classnames";

const SurveyResultsSection2024 = ({ display }) => {
  const surveyRef = useRef(null);

  useEffect(() => {
    const surveyElement = surveyRef.current;
    if (surveyElement) {
      const preventDefaultAction = (event) => event.preventDefault();

      surveyElement.addEventListener("contextmenu", preventDefaultAction);
      surveyElement.addEventListener("copy", preventDefaultAction);
      surveyElement.addEventListener("cut", preventDefaultAction);

      // Clean up the event listeners when the component unmounts or rerenders
      return () => {
        surveyElement.removeEventListener("contextmenu", preventDefaultAction);
        surveyElement.removeEventListener("copy", preventDefaultAction);
        surveyElement.removeEventListener("cut", preventDefaultAction);
      };
    }
  }, []);

  useEffect(() => {
    const surveyChildren = [...surveyRef.current.children];
    surveyChildren.forEach((section) => {
      gsap.set(section, {
        y: 400,
        opacity: 0,
      });
    });
    if (display === true) {
      surveyChildren.forEach((section, index) => {
        gsap.to(section, {
          y: 0,
          duration: 2.2,
          ease: "power4.out",
          opacity: 1,
          delay: 1.5 + index * 0.04,
        });
      });
    }
  }, [display]);

  const resultClasses = classNames("survey-results-section", {
    "survey-results-section--display": display,
  });

  return (
    <div ref={surveyRef} className={resultClasses}>
      <div className="survey-results-section__watermark">
        <h1>Document Subect to NDA</h1>
        <h1>Do Not Print or Circulate</h1>
      </div>
      <div className="survey-results-section__print-warning">
        <div>
          <h1>WARNING:</h1>
          <h3>You are attempting to violate a non-disclosure agreement.</h3>
          <p>
            "At any time, upon the request of Claypoole, you agree to return
            promptly to Claypoole Search all Confidential Information which may
            be in your possession or control. If certain Confidential
            Information cannot be returned, you agree to destroy it and to
            certify such destruction. If certain Confidential Information cannot
            be returned or destroyed, you agree that it will remain subject to
            the confidentiality provisions of this letter agreement."
          </p>
        </div>
      </div>
      <h1 className="survey-results-section__header sensitive">
        COMPENSATION OF LEGAL AND COMPLIANCE PROFESSIONALS AT ALTERNATIVE ASSET
        MANAGERS 2024 (2023 DATA SET)
      </h1>
      <div className="survey-results-section__toc sensitive">
        <h2>Table of Contents</h2>
        <div className="survey-results-section__toc__index">
          <Link to="/survey-results/2024#introduction" isAnchor>
            Introduction
          </Link>
          <Link to="/survey-results/2024#summary" isAnchor>
            Summary
          </Link>
          <Link to="/survey-results/2024#general-counsel" isAnchor>
            General Counsel
          </Link>
          <Link to="/survey-results/2024#associate-general-counsel" isAnchor>
            Associate General Counsel
          </Link>
          <Link to="/survey-results/2024#chief-compliance-officer" isAnchor>
            Chief Compliance Officer
          </Link>
        </div>
      </div>
      <div
        id="introduction"
        className="survey-results-section__introduction sensitive"
      >
        <h2>Introduction</h2>
        <p>
          For two decades, we have been conducting a survey on compensation
          levels for legal and compliance professionals at alternative managers.
          Until 2021, we collected virtually all our data through phone
          interviews. Since then, we transitioned to collecting data both online
          on our secure website and over the phone. This year, for the first
          time, we collected 100% of our data online. Unfortunately (and
          predictably), it led to decreased participation, something we suspect
          is a singular event in the history of our survey.
        </p>
        <p>
          This study provides ranges, medians, quartiles and deciles for total
          compensation (base salary + cash bonus + deferred compensation +
          equity) earned in 2023 across a variety of variables. Participants
          include General Counsel, Associate General Counsel and Chief
          Compliance Officers of alternative asset managers, including hedge
          funds, fund of funds, private equity funds, venture funds, blended
          funds, family offices and the alternative asset management divisions
          of major asset managers and financial institutions. Participants work
          in major markets, including the New York Tri-State Area, Boston,
          Chicago, Washington DC, Northern/Southern California and London. NYC,
          as always, ads in participation but the west coast is gaining ground.
        </p>
        <p>
          It's important to note that participation in the survey is voluntary,
          which may skew the results towards the lower end of the compensation
          spectrum. We know of many highly paid professionals who would fall
          into (or above) the top decile and do not participate. Therefore, the
          upper ranges of industry compensation levels are likely higher than
          reported here.
        </p>
        <p>
          The results this year are very interesting. We will be recording a
          podcast with my thoughts and speaking with an industry publication or
          two soon.
        </p>
        <p>
          <strong>
            THIS DOCUMENT HAS ALWAYS BEEN PROVIDED FREE OF CHARGE TO THOSE WHO
            CONTRIBUTE DATA, AND PARTICIPANTS HAVE BEEN ASKED NOT TO SHARE THE
            SURVEY WITH NON-CONTRIBUTERS, THE IDEA BEING THAT THE MORE DATA WE
            CAN GATHER, THE MORE USEFUL THE SURVEY WILL BE. TO DISCOURAGE
            SHARING, THE SURVEY IS BEING CIRCULATED ON A SECURE WEBSITE.
            REQUESTS FOR HARD COPIES WILL BE EVALUATED INDIVIDUALLY.
          </strong>
        </p>
      </div>
      <div id="summary" className="survey-results-section__summary sensitive">
        <h2>Summary - All Job Categories</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>All GC</p>
                <p>GC</p>
                <p>GC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>108</li>
                  <li>40</li>
                  <li>68</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$350,000</li>
                  <li>$467,500</li>
                  <li>$350,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$610,500</li>
                  <li>$570,000</li>
                  <li>$623,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$768,750</li>
                  <li>$794,375</li>
                  <li>$750,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,075,000</li>
                  <li>$1,167,500</li>
                  <li>$1,025,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,721,250</li>
                  <li>$1,432,750</li>
                  <li>$1,829,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,589,400</li>
                  <li>$2,175,200</li>
                  <li>$2,650,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$6,868,750</li>
                  <li>$6,868,750</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>All AGC</p>
                <p>AGC</p>
                <p>AGC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>62</li>
                  <li>51</li>
                  <li>11</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$150,000</li>
                  <li>$150,000</li>
                  <li>$625,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$304,000</li>
                  <li>$300,000</li>
                  <li>$650,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$425,419</li>
                  <li>$402,500</li>
                  <li>$702,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$616,244</li>
                  <li>$520,000</li>
                  <li>$1,070,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$800,000</li>
                  <li>$759,500</li>
                  <li>$1,512,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,682,500</li>
                  <li>$1,652,000</li>
                  <li>$1,725,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,680,000</li>
                  <li>$2,650,000</li>
                  <li>$2,680,000</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <p>All CCO</p>
                <p>GC/CCO</p>
                <p>AGC/CCO</p>
                <p>CCO</p>
              </td>
              <td>
                <ul>
                  <li>118</li>
                  <li>68</li>
                  <li>11</li>
                  <li>39</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$195,000</li>
                  <li>$350,000</li>
                  <li>$625,000</li>
                  <li>$195,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$447,000</li>
                  <li>$369,300</li>
                  <li>$650,000</li>
                  <li>$623,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$650,000</li>
                  <li>$460,000</li>
                  <li>$702,500</li>
                  <li>$750,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$900,000</li>
                  <li>$1,025,000</li>
                  <li>$1,070,000</li>
                  <li>$852,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,475,000</li>
                  <li>$1,829,000</li>
                  <li>$1,512,500</li>
                  <li>$886,250</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,357,500</li>
                  <li>$2,650,000</li>
                  <li>$1,725,000</li>
                  <li>$1,386,600</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,850,000</li>
                  <li>$4,850,000</li>
                  <li>$2,680,000</li>
                  <li>$4,250,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        id="general-counsel"
        className="survey-results-section__summary sensitive"
      >
        <h2>General Counsel</h2>
        <table>
          <thead>
            <tr>
              <th>
                <strong>Entire Grouping</strong>
              </th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>All GC</p>
                <p>GC</p>
                <p>GC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>108</li>
                  <li>40</li>
                  <li>68</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$350,000</li>
                  <li>$467,500</li>
                  <li>$350,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$610,500</li>
                  <li>$570,000</li>
                  <li>$623,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$768,750</li>
                  <li>$794,375</li>
                  <li>$750,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,075,000</li>
                  <li>$1,167,500</li>
                  <li>$1,025,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,721,250</li>
                  <li>$1,432,750</li>
                  <li>$1,829,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,589,400</li>
                  <li>$2,175,200</li>
                  <li>$2,650,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$6,868,750</li>
                  <li>$6,868,750</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Experience</strong>
              </th>
              <th>Sample Size</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>16 Years or More</p>
                <p>15 Years or Less</p>
              </td>
              <td>
                <ul>
                  <li>84</li>
                  <li>24</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>30</li>
                  <li>10</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>54</li>
                  <li>14</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$350,000</li>
                  <li>$367,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$632,500</li>
                  <li>$505,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$821,750</li>
                  <li>$684,250</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,163,422</li>
                  <li>$844,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,781,250</li>
                  <li>$1,281,250</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,462,500</li>
                  <li>$3,200,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$6,868,750</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Assets</strong>
              </th>
              <th>Sample Size</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>$20Bn and Over</p>
                <p>$5Bn to $19Bn</p>
                <p>Under $5Bn</p>
              </td>
              <td>
                <ul>
                  <li>14</li>
                  <li>40</li>
                  <li>54</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>6</li>
                  <li>18</li>
                  <li>16</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>8</li>
                  <li>22</li>
                  <li>38</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$490,000</li>
                  <li>$350,000</li>
                  <li>$367,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$638,000</li>
                  <li>$595,000</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$793,750</li>
                  <li>$775,000</li>
                  <li>$715,625</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,500,000</li>
                  <li>$1,225,000</li>
                  <li>$900,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,129,000</li>
                  <li>$1,837,500</li>
                  <li>$1,315,208</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,850,000</li>
                  <li>$3,510,000</li>
                  <li>$2,000,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$3,750,000</li>
                  <li>$6,868,750</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Type</strong>
              </th>
              <th>Sample Size</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Hedge</p>
                <p>PE/Venture</p>
                <p>Blended</p>
              </td>
              <td>
                <ul>
                  <li>51</li>
                  <li>19</li>
                  <li>38</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>14</li>
                  <li>8</li>
                  <li>18</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>37</li>
                  <li>11</li>
                  <li>20</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$350,000</li>
                  <li>$467,500</li>
                  <li>$490,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$575,000</li>
                  <li>$617,000</li>
                  <li>$693,300</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$725,000</li>
                  <li>$776,250</li>
                  <li>$803,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,005,000</li>
                  <li>$1,160,000</li>
                  <li>$1,170,922</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,575,000</li>
                  <li>$1,670,000</li>
                  <li>$1,880,750</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,075,000</li>
                  <li>$3,550,000</li>
                  <li>$3,038,600</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$3,800,000</li>
                  <li>$4,850,000</li>
                  <li>$6,868,750</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Location</strong>
              </th>
              <th>Sample Size</th>
              <th>GC</th>
              <th>GC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>New York</p>
                <p>Elsewhere</p>
              </td>
              <td>
                <ul>
                  <li>65</li>
                  <li>43</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>24</li>
                  <li>16</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>41</li>
                  <li>27</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$367,500</li>
                  <li>$350,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$663,500</li>
                  <li>$497,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$825,000</li>
                  <li>$675,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,166,844</li>
                  <li>$980,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,916,000</li>
                  <li>$1,376,805</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,919,200</li>
                  <li>$2,320,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$6,868,750</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        id="associate-general-counsel"
        className="survey-results-section__summary sensitive"
      >
        <h2>Associate General Counsel</h2>
        <table>
          <thead>
            <tr>
              <th>
                <strong>Entire Grouping</strong>
              </th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>All AGC</p>
                <p>AGC</p>
                <p>AGC/CCO</p>
              </td>
              <td>
                <ul>
                  <li>62</li>
                  <li>51</li>
                  <li>11</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$150,000</li>
                  <li>$150,000</li>
                  <li>$625,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$304,000</li>
                  <li>$300,000</li>
                  <li>$650,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$425,419</li>
                  <li>$402,500</li>
                  <li>$702,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$616,244</li>
                  <li>$520,000</li>
                  <li>$1,070,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$800,000</li>
                  <li>$759,500</li>
                  <li>$1,512,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,682,500</li>
                  <li>$1,052,000</li>
                  <li>$1,725,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,680,000</li>
                  <li>$2,650,000</li>
                  <li>$2,680,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Experience</strong>
              </th>
              <th>Sample Size</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>16 Years or More</p>
                <p>15 Years or Less</p>
              </td>
              <td>
                <ul>
                  <li>19</li>
                  <li>43</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>0</li>
                  <li>36</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>4</li>
                  <li>7</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$254,000</li>
                  <li>$150,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$348,000</li>
                  <li>$308,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$443,613</li>
                  <li>$420,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$650,000</li>
                  <li>$612,488</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$984,000</li>
                  <li>$786,750</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,100,656</li>
                  <li>$1,725,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,925,000</li>
                  <li>$2,680,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Assets</strong>
              </th>
              <th>Sample Size</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>$20Bn and Over</p>
                <p>$5Bn to $19Bn</p>
                <p>Under $5Bn</p>
              </td>
              <td>
                <ul>
                  <li>31</li>
                  <li>20</li>
                  <li>11</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>29</li>
                  <li>14</li>
                  <li>8</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>2</li>
                  <li>6</li>
                  <li>3</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$375,000</li>
                  <li>$200,000</li>
                  <li>$150,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$405,000</li>
                  <li>$290,000</li>
                  <li>$225,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$457,000</li>
                  <li>$390,000</li>
                  <li>$297,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$720,000</li>
                  <li>$635,000</li>
                  <li>$475,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$851,538</li>
                  <li>$816,500</li>
                  <li>$637,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,650,000</li>
                  <li>$1,342,500</li>
                  <li>$938,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,650,000</li>
                  <li>$2,680,000</li>
                  <li>$1,725,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Type</strong>
              </th>
              <th>Sample Size</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Hedge</p>
                <p>PE/Venture</p>
                <p>Blended</p>
              </td>
              <td>
                <ul>
                  <li>24</li>
                  <li>12</li>
                  <li>26</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>19</li>
                  <li>8</li>
                  <li>24</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>5</li>
                  <li>4</li>
                  <li>2</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$150,000</li>
                  <li>$225,000</li>
                  <li>$383,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$216,200</li>
                  <li>$304,000</li>
                  <li>$413,613</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$371,250</li>
                  <li>$585,000</li>
                  <li>$453,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$510,000</li>
                  <li>$1,099,178</li>
                  <li>$606,244</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$650,000</li>
                  <li>$1,406,250</li>
                  <li>$753,750</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,024,400</li>
                  <li>$2,557,500</li>
                  <li>$1,388,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,925,000</li>
                  <li>$2,680,000</li>
                  <li>$2,500,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Location</strong>
              </th>
              <th>Sample Size</th>
              <th>AGC</th>
              <th>AGC/CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>New York</p>
                <p>Elsewhere</p>
              </td>
              <td>
                <ul>
                  <li>40</li>
                  <li>22</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>31</li>
                  <li>20</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>9</li>
                  <li>2</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$200,000</li>
                  <li>$150,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$373,500</li>
                  <li>$258,600</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$468,500</li>
                  <li>$387,250</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$622,500</li>
                  <li>$517,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$800,000</li>
                  <li>$864,888</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,725,000</li>
                  <li>$1,274,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,680,000</li>
                  <li>$2,650,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        id="chief-compliance-officer"
        className="survey-results-section__summary sensitive"
      >
        <h2>Chief Compliance Officer</h2>
        <table>
          <thead>
            <tr>
              <th>
                <strong>Entire Grouping</strong>
              </th>
              <th>Sample</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>All CCO</p>
                <p>GC/CCO</p>
                <p>AGC/CCO</p>
                <p>CCO</p>
              </td>
              <td>
                <ul>
                  <li>118</li>
                  <li>68</li>
                  <li>11</li>
                  <li>39</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$195,000</li>
                  <li>$350,000</li>
                  <li>$625,000</li>
                  <li>$195,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$447,000</li>
                  <li>$623,500</li>
                  <li>$650,000</li>
                  <li>$369,300</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$650,000</li>
                  <li>$750,000</li>
                  <li>$702,500</li>
                  <li>$460,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$900,000</li>
                  <li>$1,025,000</li>
                  <li>$1,070,000</li>
                  <li>$852,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,475,000</li>
                  <li>$1,829,000</li>
                  <li>$1,512,500</li>
                  <li>$886,250</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,357,500</li>
                  <li>$2,650,000</li>
                  <li>$1,725,000</li>
                  <li>$1,386,600</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,850,000</li>
                  <li>$4,850,000</li>
                  <li>$2,680,000</li>
                  <li>$4,250,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Experience</strong>
              </th>
              <th>Sample Size</th>
              <th>GC/CCO</th>
              <th>AGC/CCO</th>
              <th>CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>16 Years or More</p>
                <p>15 Years or Less</p>
              </td>
              <td>
                <ul>
                  <li>88</li>
                  <li>30</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>54</li>
                  <li>14</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>4</li>
                  <li>7</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>30</li>
                  <li>9</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$195,000</li>
                  <li>$270,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$463,200</li>
                  <li>$369,750</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$661,875</li>
                  <li>$600,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$951,250</li>
                  <li>$752,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,500,625</li>
                  <li>$1,275,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,350,000</li>
                  <li>$2,792,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,850,000</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Assets</strong>
              </th>
              <th>Sample Size</th>
              <th>GC/CCO</th>
              <th>AGC/CCO</th>
              <th>CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>$20Bn and Over</p>
                <p>$5Bn to $19Bn</p>
                <p>Under $5Bn</p>
              </td>
              <td>
                <ul>
                  <li>25</li>
                  <li>39</li>
                  <li>54</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>8</li>
                  <li>22</li>
                  <li>38</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>2</li>
                  <li>6</li>
                  <li>3</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>15</li>
                  <li>11</li>
                  <li>13</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$400,000</li>
                  <li>$270,000</li>
                  <li>$195,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$572,600</li>
                  <li>$494,000</li>
                  <li>$400,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$725,000</li>
                  <li>$682,500</li>
                  <li>$617,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,200,000</li>
                  <li>$1,000,000</li>
                  <li>$850,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,916,000</li>
                  <li>$1,401,250</li>
                  <li>$1,197,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,650,755</li>
                  <li>$2,844,000</li>
                  <li>$2,000,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$3,750,000</li>
                  <li>$4,850,000</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Type</strong>
              </th>
              <th>Sample Size</th>
              <th>GC/CCO</th>
              <th>AGC/CCO</th>
              <th>CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>Hedge</p>
                <p>PE/Venture</p>
                <p>Blended</p>
              </td>
              <td>
                <ul>
                  <li>56</li>
                  <li>28</li>
                  <li>34</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>37</li>
                  <li>11</li>
                  <li>20</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>5</li>
                  <li>4</li>
                  <li>2</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>14</li>
                  <li>13</li>
                  <li>12</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$195,000</li>
                  <li>$370,000</li>
                  <li>$340,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$399,000</li>
                  <li>$435,000</li>
                  <li>$588,800</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$622,500</li>
                  <li>$732,500</li>
                  <li>$735,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$775,000</li>
                  <li>$1,247,639</li>
                  <li>$911,250</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,050,000</li>
                  <li>$1,988,125</li>
                  <li>$1,669,375</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,975,000</li>
                  <li>$3,575,000</li>
                  <li>$2,305,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$3,900,000</li>
                  <li>$4,850,000</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th>
                <strong>By Location</strong>
              </th>
              <th>Sample Size</th>
              <th>GC/CCO</th>
              <th>AGC/CCO</th>
              <th>CCO</th>
              <th>Low</th>
              <th>Bottom Decile</th>
              <th>Bottom Quartile</th>
              <th>Median</th>
              <th>Top Quartile</th>
              <th>Top Decile</th>
              <th>High</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <p>New York</p>
                <p>Elsewhere</p>
              </td>
              <td>
                <ul>
                  <li>75</li>
                  <li>43</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>41</li>
                  <li>27</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>9</li>
                  <li>2</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>25</li>
                  <li>14</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$195,000</li>
                  <li>$270,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$490,000</li>
                  <li>$444,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$663,750</li>
                  <li>$622,500</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,005,000</li>
                  <li>$750,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$1,662,500</li>
                  <li>$1,325,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$2,326,000</li>
                  <li>$2,350,000</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>$4,850,000</li>
                  <li>$4,850,000</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SurveyResultsSection2024;
